import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textLimit'
})

export class TextLimitPipe implements PipeTransform {
    transform(value: string, limit: number, symbol: string = "..."): any {
        return value.length > limit ? value.slice(0, limit) + "..." : value;
    }
}