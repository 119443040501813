import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { firstValueFrom } from 'rxjs';

type User = {
    email: string;
    password: string;
};

@Injectable({providedIn: 'root'})
export class AuthService {
    private apiUrl = `${environment.baseUrl}/api/cfa-demo`;
    
    constructor(
        private readonly _router: Router,
        private readonly _http: HttpClient
    ) { }
    
    async signInWithEmailAndPassword(email: string, password: string): Promise<boolean> {
        try {
            const res = await firstValueFrom(this._http.post<{status: "ok"}>(`${this.apiUrl}/sign-in`, {}, {headers: {email, password}}));
            if (res.status === "ok") {
                localStorage.setItem("user", JSON.stringify({email, password}));
                return true;
            } else {
                return false;
            }
        } catch (_) {
            return false;
        }
    }

    logout() {
        localStorage.removeItem("user");
        this._router.navigate(["/auth", "login"]);
    }

    get user(): User {
        const u = localStorage.getItem("user");
        if (u) {
            return JSON.parse(u);
        } else {
            throw new Error("forbidden");
        }
    }
}