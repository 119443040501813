import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { TextLimitPipe } from './pipes/text-limit.pipe';

const COMPONENTS = [
    HeaderComponent,
    SidebarComponent
];

const PIPES = [
    TextLimitPipe
]

@NgModule({
    declarations: [
        ...COMPONENTS,
        ...PIPES
    ],
    exports: [
        ...COMPONENTS,
        ...PIPES
    ],
    imports: [
        CommonModule,
        RouterModule
    ]
})
export class SharedModule { }
