<nav class="navbar navbar-expand-lg bg-body-tertiary px-2 py-0">
    <div class="container-fluid">
        <a class="logo  no-decoration d-flex align-items-center" [routerLink]="['/']">
            <img src="https://res.cloudinary.com/dap6pju8g/image/upload/v1706876731/g99yoigeyczz92zsvknw.png"
                alt="logo">
        </a>
        <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button> -->
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                
            </ul>
        </div>
    </div>
</nav>